<template lang="pug">
  app-menu(ref='bim-context-menu' :menu='bimContextMenuItems')
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { SelectElements } from '@/plugins/viewerTools/tools/selectElements'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

import { Node } from '@xeokit/xeokit-sdk'
import { Mesh } from '@xeokit/xeokit-sdk'
import { math } from '@xeokit/xeokit-sdk'
import { VBOGeometry } from '@xeokit/xeokit-sdk'
import { buildSphereGeometry } from '@xeokit/xeokit-sdk'
import { PhongMaterial } from '@xeokit/xeokit-sdk'
import { TaskScreenshot } from '@/components/task/taskScreenshot'
import { similarElementEnum, topPanelEnum } from '@/store/sections/LayoutRepository'

export default {
  
  computed: {
    ...mapState('task', ['selectedTask']),
    ...mapGetters('projectPermissions', ['hasTaskCreate', 'hasTaskCreateCommentAttach']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('axis', ['fixedAxisGroup', 'selectedAxis']),

    viewer() {
      return XeokitMediator.viewer
    },

    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    bimContextMenuItems() {
      const items = []
      if (this.hasTaskCreate) {
        items.push(
          {
            title: this.$tc('section.collision.menu.createTask', 0),
            action: async () => {
              try {
                TaskScreenshot.showCreateTaskWithTaskScreenshotDialog({
                  pickedEntityId: this.context.pickResult.entity?.id, 
                  pickedWorldPos: this.context.pickResult._worldPos
                })
              }
              catch (er) {
                er
              }

              this.context.hitHelper.hide()
            }
          }
        )
      }

      if (this.selectedTask && this.hasTaskCreateCommentAttach) {
        items.push({
          title: this.$tc('module.task.addToTask'),
          action: async () => {
            try {
              await TaskScreenshot.showCreateTaskScreenshotDialog({
                pickedEntityId: this.context.pickResult.entity?.id, 
                pickedWorldPos: this.context.pickResult._worldPos, 
                taskUuid: this.selectedTask.uuid
              })
            }
            catch (er) {
              er
            }

            this.context.hitHelper.hide()
          }
        })
      }

      if (this.selectedElements.length) {
        items.push({
          title: this.$tc('module.task.showSimilar'),
          action: async () => {
            let axisGroup = this.fixedAxisGroup.find(item => item.value == '5')
            let copySelectedElements = this.selectedElements.filter(Boolean)

            if (this.pickedElement && !copySelectedElements.includes(this.pickedElement)) {
              copySelectedElements.push(this.pickedElement)
            }

            const selectedElementsObject = {
              elementsUuids: copySelectedElements,
              conditionTypeSimilarElementDTO: similarElementEnum.class,
              projectUuid: this.projectUuid,
            }

            this.setTopUnit(topPanelEnum.elementTree)

            await this.LOAD_SIMILAR_ELEMENTS(selectedElementsObject).then((data) => {
              this.$nextTick(() => SelectElements.selectElements(data))
              this.selectAxis(axisGroup)
            })
            await this.loadAxisDataList(this.projectUuid)
          }
        })
      }

      return items
    },

  },

  methods: {
    ...mapMutations('project', ['setTopUnit']),
    ...mapActions('axis', ['selectAxis', 'loadAxisDataList']),
    ...mapActions('elements', ['LOAD_SIMILAR_ELEMENTS'])
  },

  async mounted() {
    await XeokitMediator.waitForViewer()

    let mouseClick
    XeokitMediator.on("mouseClick", (evt) => {
      mouseClick = evt
    })

    const hitHelper = new (function (viewer) {
      const node = new Node(viewer.scene, {
        pickable: false,
        collidable: false,
        position: [0, 0, 0],
        visible: false,
        children: [
          new Mesh(viewer.scene, {
            geometry: new VBOGeometry(viewer.scene, buildSphereGeometry({ radius: .03 })),
            material: new PhongMaterial(viewer.scene, { emissive: [1, 0, 0], diffuse: [0, 0, 0] }),
            pickable: false,
            collidable: false
          }),
        ]
      })
      node.visible = false

      this.show = function (hit) {
        node.position = hit.worldPos
        node.visible = true

        const dist = Math.abs(math.lenVec3(math.subVec3(viewer.scene.camera.eye, node.position, math.vec3([0, 0, 0]))))
        const worldSize = (Math.tan(viewer.camera.perspective.fov * math.DEGTORAD)) * dist
        const size = 0.07 * worldSize

        node.scale = [size, size, size]
      }

      this.hide = function () {
        node.visible = false
      }

    })(this.viewer)

    this.viewer.scene.input.on('mouseclicked', (canvasPos) => {
      if (mouseClick?.buttons !== 2) return

      let pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: canvasPos,
        pickSurface: false
      })

      if (!pickResult?.worldPos) return

      this.context = {
        viewer: this.viewer,
        pickResult: pickResult,
        hitHelper: hitHelper
      }

      const canvasBounds = this.viewer.scene.canvas.canvas.getBoundingClientRect()

      this.$refs['bim-context-menu'].show(new MouseEvent('mouseup', {
        clientX: canvasPos[0] + canvasBounds.left,
        clientY: canvasPos[1] + canvasBounds.top,
      }))
      hitHelper.show(pickResult)

      document.addEventListener('mousedown', () => {
        this.$refs['bim-context-menu'].close()
        hitHelper.hide()
      }, { once: true })
    })

  },

}
</script>

<style>
/* ----------------------------------------------------------------------------------------------------------*/
/* ContextMenu */
/* ----------------------------------------------------------------------------------------------------------*/

.xeokit-context-menu {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    display: none;
    z-index: 201;
    background: rgba(255, 255, 255, 0.46);
    border: 1px solid black;
    border-radius: 6px;
    padding: 0;
    width: 200px;
}

.xeokit-context-menu ul {
    list-style: none;
    margin-left: 0;
    padding: 0;
}

.xeokit-context-menu ul li {
    list-style-type: none;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: black;
    border-bottom: 1px solid gray;
    background: rgba(255, 255, 255, 0.46);
    cursor: pointer;
    /* width: calc(100% - 30px); */
}

.xeokit-context-menu ul li:hover {
    background: black;
    color: white;
    font-weight: normal;
}

.xeokit-context-menu ul li span {
    display: inline-block;
}

.xeokit-context-menu .disabled {
    display: inline-block;
    color: gray;
    cursor: default;
    font-weight: normal;
}

.xeokit-context-menu .disabled:hover {
    color: gray;
    cursor: default;
    background: #eeeeee;
    font-weight: normal;
}

  </style>
