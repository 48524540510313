<template lang='pug'>
div.d-inline.px-1.py-1( style="background: var(--v-surface-base)" )

  div( style="color: var(--v-primary-darken2)" ).d-flex.align-center
    span( style="color: var(--v-primary-darken2)" ).ml-3 {{ $t('project.sectionCube.tooltipTitle') }}
    v-btn.ml-auto( color='var(--v-primary-darken2)' icon small @click='deactivateSectionCube' )
      v-icon mdi-close-box-outline

  div.content
    div.base-scroll-body( style="overflow-x: hidden" )
      div
        div( v-if='floors.length' )
          v-divider
          v-btn.d-block.text-none.text-bnt( v-for='floor in floors' :key='floor.uuid' :outlined='floor.uuid === selectedFloor' :text='floor.uuid !== selectedFloor' width='100%' color='accent' small @click='toggleSelectedFloor(floor)' ) {{ floor.name }}
        
        div
          v-divider
          v-divider

        div( v-if='imagePlanesShort.length' )
          v-divider
          div(v-for='imagePlane in imagePlanesShort')
            div.d-flex
              v-btn.d-block.text-none.text-bnt( :outlined='imagePlane.uuid === selectedImagePlaneUuid' :text='imagePlane.uuid !== selectedImagePlaneUuid' :key='imagePlane.uuid' color='accent' small @click='toggleImagePlane(imagePlane)' ) {{ imagePlane.title }}
              
              app-tooltip(:title="$t('viewer.sectionPlane.delete')" :openDelay="300" top)
                v-btn(text small @click='deleteImagePlane(imagePlane)')
                  v-icon delete
              app-tooltip(:title="'Редактировать'" :openDelay="300" top)
                v-btn(text small @click='editImagePlane(imagePlane)')
                  v-icon mdi-pencil

        div.text-center( v-else-if='isFloorsLoading' )
          v-divider
          v-progress-circular( size='20' width='2' color='accent' indeterminate )

      div( v-if='sectionCubes.length' )
        v-divider
        div.d-flex( v-for='sectionCube in sectionCubes' :key='sectionCube.id' )
          v-btn.d-block.text-none.flex.mr-2.text-bnt( color='accent' small text @click='toggleSectionCubeAabb(sectionCube.aabb)' ) {{ sectionCube.title }}
          
          v-btn( icon small )
            v-icon( small color='var(--v-primary-darken2)' @click='patchSectionCube(sectionCube)' ) mdi-pencil
          
          v-btn( icon small )
            v-icon( small color='var(--v-primary-darken2)' @click='deleteSectCubes()' ) close
  
  v-divider

  div.d-flex.align-center
    div.mr-2.d-flex.align-center
      app-tooltip( v-for='[title, icon, action] in actions' :key='icon' :title='title' top )
        v-btn( icon color='var(--v-primary-darken2)' @click='action' )
          v-icon {{ icon }}

    v-btn.ml-auto( color='accent' small @click='createSectionCube()' ) {{ $t('project.sectionCube.controlPanel.saveState') }}

  app-dialog( v-model='sectionCubeDialogShow' :confirm-disabled='!sectionCube.title' :header='$t(`project.sectionCube.controlPanel.${sectionCube.id ? "editCubeDialogTitle" : "newCubeDialogTitle"}`)' 
              @confirm='sectionCubeDialogConfirm(true)' :confirm-text='$t("button.save")' @close='sectionCubeDialogConfirm(false)' )
    app-section
      app-text-field( v-model='sectionCube.title' :placeholder='$t("project.sectionCube.controlPanel.cubeTitleField")' hide-details )
      v-checkbox.mb-0.v-chk( v-if="showCheckbox" v-model="isChecked" :label="$t('project.sectionCube.controlPanel.saveState')" light )
  app-dialog-confirm( v-model="dialog.delSectCube" @confirm='deleteSectionCube(sectionCube.id)' )
    span {{ $t('project.sectionCube.controlPanel.warningDelete', {name: sectionCube.title}) }} 

  
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { FloorBoundsCache } from '@/plugins/xeokit/floors/floorBoundsCache'
import { mergeAABBs } from '@/plugins/xeokit/sectionCube/aabb.utils'

const sectionCubeDefault = Object.freeze({
  id: null,
  aabb: null,
  title: null,
})

export default {
  name: 'BimSectionCube',

  data() {
    return {
      sectionCubeDialogShow: false,
      sectionCube: Object.assign({}, sectionCubeDefault),
      isChecked: false,
      showCheckbox: false,
      dialog: {
        delSectCube: false,
      }
    }
  },

  computed: {
    floors() { 
      return XeokitMediator.SectionCube.floors 
    },

    isFloorsLoading() { 
      return XeokitMediator.SectionCube.isFloorsLoading 
    },

    sectionCubes() { 
      return XeokitMediator.SectionCube.sectionCubes 
    },

    selectedFloor() { 
      return XeokitMediator.SectionCube.selectedFloor 
    },

    isSectionCubeVisible() {
      return XeokitMediator.SectionCube.visible
    },

    imagePlanesShort() {
      return XeokitMediator.ImagePlanes.imagePlanesShort
    },

    selectedImagePlaneUuid() {
      return XeokitMediator.ImagePlanes.selectedImagePlaneUuid
    },

    actions() {
      return [
        ['Описать элементы', 'mdi-box-shadow', this.showSelectedElements],
        ['Показать/Скрыть управление', this.isSectionCubeVisible ? 'mdi-eye' : 'mdi-eye-off', this.toggleSectionCubeVisible],
        ['Вид по умолчанию', 'mdi-undo', this.setSectionCubeDefaultView],
      ]
    }
  },

  async beforeMount() {
    XeokitMediator.SectionCube.loadFloors()
    XeokitMediator.ImagePlanes.loadImagePlanesShort()
  },

  methods: {
    showSelectedElements() {
      const selectedElements = XeokitMediator.viewer.scene.selectedObjectIds.concat(XeokitMediator.viewer.scene.highlightedObjectIds)
      if (!selectedElements.length) return

      const aabb = mergeAABBs(selectedElements.map(elementId => XeokitMediator.viewer.scene.objects[elementId].aabb))

      this.setSectionCubeAabb(aabb)
    },

    _createSectionCube() {
      XeokitMediator.SectionCube.createSectionCube({
        title: 'SectionCube_' + this.sectionCubes.length,
        aabb: XeokitMediator.SectionCube.aabb,
      })
    },

    toggleSelectedFloor(floor) {
      const isSelected = floor.uuid === this.selectedFloor
      XeokitMediator.SectionCube.setSelectedFloor(isSelected ? null : floor.uuid)
      
      if (!isSelected) {
        const [aabb, objectIds] = FloorBoundsCache.getFloorBounds(floor)
        XeokitMediator.SectionCube.setAabb(aabb)

        XeokitMediator.ElementsSettings.setElementsVisible(XeokitMediator.viewer.scene.objectIds, false)
        XeokitMediator.ElementsSettings.setElementsVisible(objectIds, true)
      }

      else {
        XeokitMediator.ElementsSettings.setElementsVisible(XeokitMediator.viewer.scene.objectIds, true)
      }
    },

    toggleSectionCubeAabb(aabb) {
      XeokitMediator.SectionCube.setSelectedFloor(null)
      XeokitMediator.ElementsSettings.setElementsVisible(XeokitMediator.viewer.scene.objectIds, true)
      this.setSectionCubeAabb(aabb)
    },

    toggleSectionCubeVisible() {
      XeokitMediator.SectionCube.setVisible(!this.isSectionCubeVisible)
    },

    deactivateSectionCube() {
      this.$emit('input', false)
      XeokitMediator.SectionCube.setActive(false)
    },

    setSectionCubeDefaultView() {
      const aabb = XeokitMediator.viewer.scene.getAABB(XeokitMediator.viewer.scene.objectIds)
      this.setSectionCubeAabb(aabb)
    },

    setSectionCubeAabb(aabb) {
      XeokitMediator.SectionCube.setAabb(aabb)
    },

    deleteSectCubes () {
      this.dialog.delSectCube = true
    },

    deleteSectionCube(sectionCubeUuid) {
      XeokitMediator.SectionCube.deleteSectionCube(sectionCubeUuid)
      this.dialog.delSectCube = false
    },

    async createSectionCube() {
      this.sectionCube = Object.assign({}, sectionCubeDefault)
      this.sectionCube.aabb = XeokitMediator.SectionCube.aabb
      this.sectionCube.title = 'Куб сечений ' + new Date().toLocaleString()
      this.sectionCubeDialogShow = true


      const confirmed = await new Promise(resolve => {
        this.sectionCubeDialogConfirm = resolve
      })

      if (confirmed) {
        this.sectionCube.aabb = XeokitMediator.SectionCube.aabb
        XeokitMediator.SectionCube.createSectionCube(this.sectionCube)
      }
    },

    async patchSectionCube(sectionCube) {
      this.sectionCube = Object.assign({}, sectionCube)
      this.sectionCubeDialogShow = true
      this.showCheckbox = true
      
      const confirmed = await new Promise(resolve => {
        this.sectionCubeDialogConfirm = resolve
      })

      if (confirmed) {
        if(this.isChecked){
          this.sectionCube.aabb = XeokitMediator.SectionCube.aabb
        }
        XeokitMediator.SectionCube.patchSectionCube(this.sectionCube)
      }
      this.showCheckbox = false
    },

    async toggleImagePlane(imagePlaneShort) {
      XeokitMediator.ImagePlanes.selectImagePlaneByUuid(imagePlaneShort.uuid)
    },

    async deleteImagePlane(imagePlaneShort) {
      XeokitMediator.ImagePlanes.deleteImagePlaneByUuid(imagePlaneShort.uuid)
    },

    async editImagePlane(imagePlaneShort) {
      XeokitMediator.ImagePlanes.editImagePlaneByUuid(imagePlaneShort.uuid)
    }
  },

  beforeDestroy() {
    XeokitMediator.ImagePlanes.destroyImagePlanes()
  }
}
</script>

<style lang="scss" scoped>
.content {
  min-height: 150px;
  height: 150px;
  max-height: 170px;
  max-width: 300px;
}

.text-bnt ::v-deep span {
  width: 100%;
  justify-content: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-chk {
  text-align: start;
  margin: 0;
  font-size: 10px;
}
</style>
