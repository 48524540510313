<template lang="pug">
.fullscreen(id="measurementScrim")
  bim-viewer
  layout-deck
  project-loading-progress()
  drawing-on-model-set-alert
  //- project-loading-progress(loaderType="las")

  .interface-overlay.through
    project-toolbar.mb-2.interactive( :logo="logo" )

    .d-flex.align-end.fullscreen
      project-layer-left( id="left-panel" )
      project-layer-bottom( id="bottom-panel" )
      project-layer-right.interactive.layers( id="right-panel" )

      bim-context-menu
  
  //- .d-flex.section-cube-controls-panel-overlay.pa-2.mr-2( v-if='isSectionCubeActive && rework_isVisibleOldPlugins' )
    //- bim-section-cube.mr-right-panel

  distance-measurement( v-if='isDistanceMeasurementActive && rework_isVisibleOldPlugins' )

  FPS-meter.project-fps-meter(v-if='showFPS')
</template>

<script>
import ProjectLayerLeft from "@@/project/layer/ProjectLayerLeft.vue";
import ProjectLayerRight from "@@/project/layer/ProjectLayerRight.vue"
import ProjectLayerBottom from "@@/project/layer/ProjectLayerBottom.vue"
import ProjectToolbar from "@/components/project/common/ProjectToolbar.vue"

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { mapStores } from 'pinia'
import { panelEnum } from '@/store/sections/LayoutRepository'

import BimViewer from '@/components/project/bim/BimViewer.vue'
import BimContextMenu from '@/components/project/bim/BimContextMenu.vue'

import DistanceMeasurement from '@/components/project/common/distanceMeasurment/DistanceMeasurement.vue'
import ProjectLoadingProgress from '@/components/project/common/ProjectLoadingProgress.vue'
import BimSectionCube from '@/components/project/bim/BimSectionCube.vue'
import FPSMeter from '@/components/project/panel/right/FPSMeter.vue'
import DrawingOnModelSetAlert from "@/components/drawings/DrawingOnModelSetAlert.vue"

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { CollisionBimAnnotations } from '@/components/project/panel/left/components/collision/collisionBimAnnotations'
// import { LocationControlBimAnnotations } from '@/components/project/panel/left/locationControl/locationControlBimAnnotations'

import { utils } from '@xeokit/xeokit-sdk'

import LayoutDeck from '@/components/layout/LayoutDeck.vue'

// import WormNodeType from '@/assets/model/worm/WormNodeType'
import ProjectWorm from '@/assets/model/worm/ProjectWorm'
import WormNode from '@/assets/model/worm/WormNode'
import WormVersion from '@/assets/model/worm/WormVersion'

import { webSocketConnect, webSocketDisConnect} from "/src/api/socket/notificationSocket.js"
import { PanelController } from '@/pages/panelController'

import { FloorBoundsCache } from "@/plugins/xeokit/floors/floorBoundsCache.js"

import { useSearchElementsStore } from '@/pinia/searchElements.module'

export default {

  components: {
    panelEnum,
    ProjectToolbar,
    ProjectLayerLeft,
    ProjectLayerRight,
    ProjectLayerBottom,
    BimSectionCube,
    BimViewer,
    BimContextMenu,
    LayoutDeck,
    DistanceMeasurement,
    ProjectLoadingProgress,
    FPSMeter,
    DrawingOnModelSetAlert
  },

  props: {
    uuid: String,
    logo: {
      type: String,
      default: 'logo'
    },
    glmem: {
      type: String,
      default: '0'
    },
  },

  data () {
    return {
      mouseClick: null,
    }
  },

  created () {
    if (this.glmem == '1') XeokitMediator.GlMemoryInfo.load()
    this.$store.dispatch('project/initUUID', this.uuid)

    // START Добавление роли для пользователя по ссылке(приглашению) Должно отработать до запроса проекта!!!
    let inviteToProject = utils.queryString["invite"]
    if (inviteToProject) this.$store.dispatch('project/addMemberInvite', inviteToProject)
    // END Добавление роли для пользователя по ссылке(приглашению)

    this.$app.version.useTariff("project")
  },

  async mounted () { 

    let getSelectedTask = utils.queryString["selectedTask"]
    let openGlobalTab = utils.queryString["open"]

    this.setPickedTask(getSelectedTask)
    this.setGlobalTabFromMail(openGlobalTab)
    this.getPanelsStatus()
    
    let measurementScrim = document.getElementById("measurementScrim")
    if (this.modelBackground) {
      measurementScrim.style.backgroundColor = this.modelBackground?.value || "none"
    }

    let getSelectedWorkSpace = utils.queryString["workSpace"]
    if (getSelectedTask && getSelectedTask!==undefined && getSelectedWorkSpace !== undefined) {
      this.updateWorkSpace(getSelectedWorkSpace).then(() => {
        this.setSelectedTask({uuid: getSelectedTask})
        this.setTopUnit('task')
      })
    }
    
    await XeokitMediator.waitForViewer()

    if(this.glmem == '1') XeokitMediator.GlMemoryInfo.init()

    this.$store.dispatch('project/init', this.uuid)

    if (openGlobalTab && openGlobalTab!==undefined) {
      this.setActiveGlobalTab(openGlobalTab)
    }

    if (localStorage.getItem("theme")) {
      this.$store.dispatch('theme/SET_THEME', JSON.parse(localStorage.getItem("theme")))
      this.$vuetify.theme.themes.dark.surface = JSON.parse(localStorage.getItem("theme")).style
    }

    XeokitMediator.MouseNavigation.setMouseNavigation()

    TaskBimAnnotations.activate()
    CollisionBimAnnotations.activate()
    // LocationControlBimAnnotations.activate()

    PanelController.initPanels()
    webSocketConnect()
    this.$store.dispatch("elements/LOAD_UNITTYPES")
    this.$store.dispatch("elements/LOAD_POWERTYPES")
  },

  beforeDestroy() {
    this.$store.dispatch('process/stopUpdating')
    XeokitMediator.reset()
    FloorBoundsCache.clear()
    
    PanelController.clearResizeObservers()

    this.$store.commit('project/resetState')
    this.$store.commit('smeta/resetState')
    this.$store.commit('task/resetState')
    this.$store.commit('plugins/resetState')
    this.$store.commit('axis/tree/resetState')
    this.$store.commit('axis/resetState')
    this.$store.commit('forms/resetState')
    this.$store.commit('viewerSectionPlane/resetState')
    this.$store.commit('selected/resetState')
    this.$store.commit('drawings/resetState')
    this.$store.commit('rule/resetState')
    this.$store.commit('collision/table/resetState')
    this.$store.commit('collision/search/resetState')
    this.$store.commit('collision/resetState')
    this.$store.commit('worm/resetState')
    this.$store.commit('patches/resetState')
    this.$store.commit('document/resetState')
    this.$store.commit('template/resetState')
    this.$store.commit('distanceMeasurement/resetState')
    this.$store.commit('cloud/resetState')
    this.$store.commit('projectDict/resetState')
    
    this.$store.dispatch('channel/resetState')
    this.$store.dispatch('receiver/resetState')
    this.$store.dispatch('maintenance/resetState')
    this.searchElementsStore.resetFilterSettings()

    XeokitMediator.ModelFulcrum.destroy()

    // WormNodeType.deleteAll()
    ProjectWorm.deleteAll()
    WormNode.deleteAll()
    WormNode.commit((state) => {
      state.editingRuleUuid = null
      state.openedNodes = []
    })
    WormVersion.deleteAll()

    webSocketDisConnect()
  },

  computed: {
    ...mapGetters('authUser', ['modelBackground']),
    ...mapState('project', ['activeGlobalTab']),
    ...mapState('project', ['topUnit']),
    ...mapGetters('authUser', ['myUUID']),
    ...mapGetters('project', ['projectUuid']),
    ...mapState('collision/search', ['collisionSelectedGlobalIds']),
    ...mapStores(useSearchElementsStore),

    rework_isVisibleOldPlugins () {
      return window.settings?.rework?.projectTools?.oldPlugins ?? false
    },

    isSectionCubeActive() {
      return XeokitMediator.SectionCube.active
    },

    isPickCoordinateViewerActive() {
      return XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive
    },

    isDistanceMeasurementActive() {
      return XeokitMediator.DistanceMeasurement.isDistanceMeasurementActive
    },

    showFPS() {
      return window.settings.showFPS
    },

    pickSectionPlanesActive() {
      return XeokitMediator.SectionPlanes.active
    }
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab', 'setTopUnit', 'setPickedTask', 'setGlobalTabFromMail']),
    ...mapMutations('task', ['setSelectedTask']),
    ...mapActions('project', ['setProjectPreview', 'addMemberInvite', 'getPanelsStatus', 'postPanelsStatus', 'updateWorkSpace']),

    showGlInfo() {
      if (this.glExt) console.log(JSON.stringify(this.glExt.getMemoryInfo(), null, 2))    
    },
  }
}
</script>

<style lang="scss" scoped>
.interface-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
}

.through {
  pointer-events: none;
}

.interactive {
  pointer-events: all;
}

.fullscreen {
  height: calc(100vh - 0px);
  width: 100vw;
  overflow: hidden;
}

.section-cube-controls-panel-overlay {
  z-index: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: inline;
}

.mr-right-panel {
  margin-right: calc(var(--right-panel-width) + 8px);
}

.project-fps-meter {
  right: 0px;
  bottom: 0px;
  position: fixed;

  font-family: 'Consolas';

  z-index: 1;

  background: black;
  color: #4caf50;

  padding: 0px 8px;
}
</style>